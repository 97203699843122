import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
// import Header from './header'
import { SiteQuery } from '../generated/graphql'
import SEO from './seo'

// import './layout.css'
import './globalFonts.css'

// import bg from '../images/bg.svg'

const pageQuery = graphql`
	query Site {
		site {
			siteMetadata {
				title
			}
		}
	}
`

const Layout: React.FunctionComponent = ({ children }) => {
	const data: SiteQuery = useStaticQuery(pageQuery)

	return (
		<>
			{/* <Header siteTitle={data.site.siteMetadata.title} /> */}
			<SEO keywords={[`valid`, `assinar`, `documentos`, `assinatura`, `vidaas`]} title='Assinar' />
			<main className='flex flex-col items-center lg:min-h-main relative bg-image'>{children}</main>
			{/* <div className='skewed' /> */}
			{/* <img src={bg} className='bg' /> */}
			{/* <div className='bg-gray-200 absolute top-0 w-full lg:min-h-main h-screen -z-2' /> */}
		</>
	)
}

export default Layout
