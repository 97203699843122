import React from 'react'
// import Logo from './logo'
// import { navigate } from 'gatsby'

import logo from '../images/logo.png'
import bg from '../images/bg.png'
import styled from 'styled-components'
import { Link } from 'gatsby'

const BgDiv = styled.div`
	background-image: url(${bg});
	background-repeat: no-repeat;
	/* background-position: center; */
	background-size: cover;
`

const Header: React.FunctionComponent = () => {
	return (
		<>
			<BgDiv className='flex w-full h-header-mobile lg:h-header justify-between items-center py-3 pl-12 pr-6 md:px-20 lg:px-24 xl:px-40'>
				<img src={logo} />
				{/* <Logo className='cursor-pointer' onClick={() => navigate('/')} /> */}
				<Link
					className='cursor-pointer no-underline text-white font-bold font-title w-header-text bg-blue-bg px-3 py-0 my-1 rounded-md text-xs lg:w-auto lg:bg-transparent lg:px-0 lg:py-0 lg:text-lg'
					//  onClick={() => navigate('/')}
					to='/'
				>
					Assinatura Digital de Documentos
				</Link>
			</BgDiv>
		</>
	)
}

export default Header
